.popup {
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  font-family: "Inter", "Helvetica Neue", "Arial", "sans-serif";
}

.popup_for_entry {
  background-color: transparent;
}

.popup__img {
  object-fit: cover;
  max-width: 75vw;
  max-height: 75vh;
  background-position: center;
  background-size: contain;
}

.popup__img_tooltip {
  margin-top: 60px;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
}

.popup__container {
  position: relative;
  width: 396px;
}

.popup__container_for_entry {
  align-self: center;
  background-color: transparent;
}

.popup__name-form {
  width: 100%;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin: 40px 0 40px;
  text-align: left;
}

.popup__name-form_for_entry {
  color: #ffffff;
}

.popup__form {
  border: none;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.popup__input-span {
  align-self: flex-start;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #8b8b8b;
  margin: 4px 0 10px;
}

.popup__input-container {
  margin-top: 15px;
  counter-reset: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: none;
}

.popup__input-container:first-child {
  margin-top: 0;
}

.popup__input {
  background-color: #2f2f2f;
  padding: 0;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  border: none;
  border-radius: 8px;
  width: 100%;
  height: 45px;
}

.popup__input_for_entry {
  color: #ffffff;
}

.popup__input_type_error:last-of-type {
  margin-bottom: 0;
}

.popup__input-error {
  display: none;
  align-self: flex-start;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #ff0000;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 10px;
}

.popup__input-error_active {
  display: flex;
}

.popup__button-save {
  width: 100%;
  height: 47px;
  margin: 0;
  padding: 0;
  background: #000000;
  border: none;
  border-radius: 3px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
}

.popup__button-save:hover {
  opacity: 0.8;
  transition: opacity 0.5s ease 0s;
}

.popup__button-save_for_entry {
  background: #4285f4;
  color: #fff;
  margin-top: 90px;
}

.popup__button-save_for_login {
  margin-top: 175px;
}

.popup__button-save_inactive {
  color: #000000;
  border: 1px solid #000000;
  opacity: 0.5;
  pointer-events: none;
}

.popup__span-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #8b8b8b;
  margin-top: 18px;
}

.popup__span-link {
  color: #4285f4;
  text-decoration: none;
}

.popup__span-link:hover {
  opacity: 0.8;
  transition: opacity 0.5s ease 0s;
}

@media screen and (max-width: 480px) {
  .popup__container {
    width: 260px;
  }

  .popup__name-form {
    text-align: center;
    margin: 50px 0 80px;
  }

  .popup__button-save_for_entry {
    font-size: 12px;
    line-height: 15px;
    margin-top: 250px;
  }

  .popup__button-save_for_login {
    margin-top: 235px;
  }
}

.popup__button-close {
  width: 32px;
  height: 32px;
  background: #ffffff;
  background-image: url(../../images/popup__button-close.svg);
  background-size: cover;
  background-color: transparent;
  border: none;
  position: absolute;
  top: -40px;
  right: -40px;
  cursor: pointer;
}
.popup__button-close:hover {
  opacity: 0.8;
  transition: opacity 0.5s ease 0s;
}
@media screen and (max-width: 640px) {
  .popup__button-close {
    width: 20px;
    height: 20px;
    right: 0;
    top: -36px;
  }
}
