.search {
  display: flex;
  max-width: 1280px;
  width: 89%;
  align-items: center;
  flex-direction: column;
  margin: 0 auto 0;
}

.search__form {
  margin: 70px 0 10px;
  border-bottom: 1px solid #2f2f2f;
  width: 100%;
}

.search__container {
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2f2f2f;
  margin-bottom: 70px;
  border-radius: 9px;
}

.search__image-loop {
  width: 31px;
  height: 15px;
  margin-left: 25px;
  background-image: url("../../images/search__image-loop.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.search__input {
  outline: none;
  width: 100%;
  background-color: transparent;
  border: none;
  height: 20px;
  color: #fff;
  padding: 0;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  margin-right: 30px;
  margin-left: 18px;
}

.search__input:focus {
  border: solid 1px #ccc;
}

.search__button {
  background: #4285f4;
  border: none;
  border-radius: 48px;
  width: 83px;
  height: 34px;
  background-image: url("../../images/search.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.search__button:hover {
  opacity: 0.8;
  transition: opacity 0.5s ease 0s;
}

.search__button-text {
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  color: #fff;
  margin: 0;
}

@media screen and (max-width: 1160px) {
  .search__form {
    max-width: 768px;
  }
}

@media screen and (max-width: 900px) {
  .search {
    max-width: 710px;
  }

  .search__form {
    margin-top: 80px;
  }

  .search__container {
    margin-bottom: 110px;
  }

  .search__button {
    width: 110px;
  }

  .search__image-loop {
    margin-left: 28px;
  }
}

@media screen and (max-width: 645px) {
  .search {
    height: 260px;
  }

  .search__image-loop {
    display: none;
  }

  .search__container {
    flex-wrap: wrap;
  }

  .search__form {
    max-width: 292px;
    min-height: 180px;
    margin-bottom: 0;
  }

  .search__input {
    font-size: 14px;
    max-width: 100px;
    height: 22px;
    margin-top: 15px;
    margin-left: 15px;
  }

  .search__button {
    margin-top: 20px;
    margin-right: 15px;
    width: 60px;
  }
}
