.portfolio {
  max-width: 1140px;
  min-width: 292px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-left: 70px;
  margin-right: 70px;
  padding-bottom: 40px;
}

.portfolio__title {
  font-style: normal;
  margin-top: 105px;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #8b8b8b;
}

.portfolio__ul-links {
  list-style: none;
  counter-reset: list;
  padding: 0;
}

.portfolio__li-container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid #424242;
  list-style: none;
  text-decoration: none;
}

.portfolio__li-container:nth-child(2) {
  margin-top: 30px;
}

.portfolio__li-container:last-of-type {
  border-bottom: none;
}

.portfolio__link {
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 52px;
  color: #fff;
}

.portfolio__link:hover {
  opacity: 0.6;
  transition: opacity 0.5s ease 0s;
}

.portfolio__link-image {
  background-image: url(../../images/portfolio__link-image.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin: 18px 5px 0 0;
  width: 18px;
  height: 18px;
}

.portfolio__link-image:hover {
  opacity: 0.6;
  transition: opacity 0.5s ease 0s;
}

@media screen and (max-width: 900px) {
  .portfolio {
    max-width: 728px;
    margin-left: 50px;
    margin-right: 50px;
    padding-bottom: 5px;
  }

  .portfolio__title {
    width: 250px;
    margin-top: 90px;
    padding-bottom: 6px;
  }
}

@media screen and (max-width: 640px) {
  .portfolio {
    max-width: 292px;
    margin-left: auto;
    margin-right: auto;
  }

  .portfolio__title {
    margin: 68px auto 0;
    width: 292px;
    font-size: 14px;
    color: #a0a0a0;
  }

  .portfolio__li-container {
    width: 292px;
    margin: 13px auto 0;
    padding-bottom: 19px;
  }

  .portfolio__li-container:nth-child(4) {
    padding-bottom: 70px;
  }

  .portfolio__link {
    font-size: 17px;
    line-height: 27px;
    margin-top: 8px;
  }

  .portfolio__link-image {
    width: 11px;
    height: 11px;
    margin-top: 13px;
  }
}
