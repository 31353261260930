.movie-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #222222;
  border-radius: 6px;
}

.movie-card__text-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 18px 0 18px;
}

.movie-card__image {
  width: 100%;
  height: 100%;
}

.movie-card__title {
  max-width: 70%;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  margin: 0 18px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.movie-card__time {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #8b8b8b;
  margin: 0;
  margin-right: 18px;
}

.movie-card__button-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  color: #ffffff;
  margin: 0;
}

.movie-card__button {
  width: 100px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 18px 0 18px;
  background-color: #313131;
  border-radius: 30px;
  cursor: pointer;
  border: none;
}

.movie-card__link {
  width: 100%;
  height: 200px;
}

.movie-card__button:hover {
  opacity: 0.8;
  transition: opacity 0.5s ease 0s;
}

.movie-card__button_red {
  background-color: #ee3465;
}

@media screen and (max-width: 800px) {
  .movie-card__link {
    height: 190px;
  }
}

@media screen and (max-width: 745px) {
  .movie-card__title {
    font-size: 13px;
    margin-left: 12px;
  }

  .movie-card__time {
    font-size: 11px;
    margin-right: 12px;
  }
}

@media screen and (max-width: 645px) {
  .movie-card__title {
    font-size: 12px;
    margin-left: 14px;
  }

  .movie-card__time {
    font-size: 10px;
    margin-right: 14px;
  }

  .movie-card__text-container {
    margin-top: 14px;
    margin-bottom: 14px;
  }

  .movie-card__button {
    margin-top: 14px;
    margin-bottom: 14px;
  }
}
