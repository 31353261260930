.header {
  margin: 0 auto;
  max-width: 1280px;
  width: 89%;
  min-height: 74px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-main {
  margin: 0 auto;
  max-width: 1280px;
  min-height: 74px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 70px 0;
}

.header__small {
  max-width: 396px;
  margin: 130px auto 0;
  display: flex;
  justify-content: flex-start;
}

.header__logo {
  background-image: url(../../images/page__logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 38px;
  height: 38px;
  color: #ffffff;
}

.header__logo-link:hover {
  opacity: 0.6;
  transition: opacity 0.5s ease 0s;
}

@media screen and (max-width: 1160px) {
  .header {
    max-width: 768px;
  }
}

@media screen and (max-width: 900px) {
  .header {
    max-width: 710px;
  }

  .header-main .header {
    max-width: 728px;
    padding: 0 25px 0;
  }
}

@media screen and (max-width: 645px) {
  .header {
    max-width: 292px;
  }

  .header-main {
    max-width: 320px;
    padding: 0 auto 0;
  }
}

@media screen and (max-width: 640px) {
  .header-main {
    max-width: 292px;
    padding: 0 14px 0;
  }

  .header-main__logo {
    width: 103px;
    height: 24px;
    margin-left: 27px;
  }
}

@media screen and (max-width: 480px) {
  .header__small {
    max-width: 260px;
    justify-content: center;
  }
}
