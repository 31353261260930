.page-not__title {
  font-weight: 400;
  font-size: 140px;
  line-height: 169px;
  text-align: center;
  color: #fff;
  margin: 0;
  margin-top: 245px;
}

.page-not__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #fff;
  margin: 0;
  margin-top: 5px;
}

.page-not__link {
  display: block;
  margin-top: 184px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #4285f4;
  margin: 0;
  margin-top: 184px;
  text-decoration: none;
}

.page-not__link:hover {
  opacity: 0.6;
  transition: opacity 0.5s ease 0s;
}

@media screen and (max-width: 900px) {
  .page-not__title {
    margin-top: 408px;
  }
}

@media screen and (max-width: 645px) {
  .page-not__title {
    margin-top: 330px;
    font-size: 80px;
    line-height: 97px;
  }

  .page-not__text {
    margin-top: 10px;
    font-size: 12px;
    line-height: 15px;
  }

  .page-not__link {
    margin-top: 284px;
    font-size: 12px;
    line-height: 15px;
  }
}
