.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navigation-main {
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
}

.navigation-main__text {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
}

.navigation-main__text_button {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000;
}

.navigation-main__link {
  color: #fff;
  text-decoration: none;
  z-index: 1;
  height: fit-content;
}

.navigation-main__link:hover {
  opacity: 0.6;
  transition: opacity 0.5s ease 0s;
}

.navigation-main__link_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76px;
  height: 32px;
  color: #000000;
  background-color: #3ddc84;
  border-radius: 3px;
  text-decoration: none;
}

.navigation-main__link_button:hover {
  opacity: 0.6;
  transition: opacity 0.5s ease 0s;
}

.navigation__link {
  color: #ffffff;
  margin: 0;
  margin-right: 20px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.navigation__link:hover {
  opacity: 0.6;
  transition: 0.6s;
}

.navigation__link_active {
  font-weight: 500;
}

.navigation__link:last-child {
  margin-left: 40px;
  margin-right: 0;
  display: inline-flex;
  align-items: center;
}

.navigation__icon {
  margin-left: 10px;
  padding: 6px;
  border-radius: 50%;
  background-color: #313131;
  width: 12px;
  display: inline;
}

.navigation__text {
  margin: 0;
  display: inline;
}

.navigation__burger-menu {
  display: none;
}

@media screen and (max-width: 900px) {
  .navigation-main {
    margin-right: 0;
  }
}

@media screen and (max-width: 800px) {
  .navigation {
    margin-right: 30px;
  }

  .navigation_none {
    display: none;
  }

  .navigation__burger-menu {
    display: block;
    padding: 0;
    width: 28px;
    height: 28px;
    background-color: rgba(1, 1, 1, 0);
    border: none;
    position: relative;
    cursor: pointer;
    z-index: 3;
  }

  .navigation__burger-menu:hover {
    opacity: 0.6;
    transition: opacity 0.5s ease 0s;
  }

  .navigation__burger-shadow {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    position: fixed;
    background-color: #000000;
    opacity: 0.3;
  }

  .navigation__burger-container {
    width: 68%;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    background-color: #202020;
    position: fixed;
    z-index: 2;
  }

  .navigation__burger-line {
    display: block;
    position: absolute;
    box-sizing: border-box;
    border: 1.5px solid #fff;
    background-color: #fff;
    width: 100%;
    margin-bottom: 3px;
    transition: ease-in 0.2s;
  }

  .navigation__burger-line:nth-child(1) {
    top: 0;
    transform-origin: left center;
  }

  .navigation__burger-line:nth-child(2) {
    top: 10px;
    transform-origin: left center;
  }

  .navigation__burger-line:nth-child(3) {
    top: 20px;
    transform-origin: left center;
  }

  .navigation__burger-line_active:nth-child(1) {
    transform: rotate(45deg);
  }

  .navigation__burger-line_active:nth-child(2) {
    transform: scale(0);
  }

  .navigation__burger-line_active:nth-child(3) {
    transform: rotate(-45deg);
  }

  .navigation__link-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navigation__panel-link {
    color: #ffffff;
    margin: 0;
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 4px;
    margin-bottom: 24px;
  }

  .navigation__panel-link:hover {
    opacity: 0.6;
    transition: opacity 0.5s ease 0s;
  }

  .navigation__panel-link_active {
    border-bottom: solid 2px #fff;
  }

  .navigation__panel-link_margin_bottom {
    display: inline-flex;
    align-items: center;
    margin-bottom: 90px;
    font-size: 13px;
    line-height: 12px;
  }

  .navigation__panel-link_margin_top {
    margin-top: 159px;
  }
}

@media screen and (max-width: 640px) {
  .navigation,
  .navigation-main {
    gap: 14px;
  }

  .navigation-main__text {
    font-size: 10px;
  }

  .navigation-main__link_button {
    width: 54px;
    height: 26px;
  }

  .navigation__burger-container {
    width: 100%;
  }

  .navigation__panel-link_margin_top {
    margin-top: 144px;
  }

  .navigation__panel-link {
    padding-bottom: 7px;
    margin-bottom: 17px;
  }

  .navigation__panel-link_margin_bottom {
    margin-bottom: 46px;
  }
}
