.profile {
  max-width: 410px;
  width: 100%;
  margin: 74px auto 0;
}

.profile__title {
  width: 100%;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 120px;
  color: #ffffff;
}

.profile__input-container {
  width: 100%;
  display: flex;
  border-bottom: solid 1px #424242;
}

.profile__input-container:last-of-type {
  border: none;
}

.profile__input-text {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: left;
  color: #ffffff;
  width: 100%;
  margin: 0;
  padding: 9px 0 18px;
}

.profile__input {
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-align: right;
  color: #ffffff;
  background-color: rgba(1, 1, 1, 0);
  border: none;
  outline: none;
  width: 100%;
  padding: 9px 0 18px;
}

#edit-email {
  padding-bottom: 8px;
  padding-top: 16px;
}

.profile__button {
  display: flex;
  margin: 0;
  margin: 205px auto 16px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  background-color: rgba(1, 1, 1, 0);
  border: none;
}

.profile__button_inactive {
  display: flex;
  margin: 0;
  margin: 205px auto 16px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  background-color: transparent;
  border: none;
  opacity: 0.2;
  pointer-events: none;
}

.profile__button:hover {
  opacity: 0.8;
  transition: opacity 0.5s ease 0s;
}

.profile__link {
  text-decoration: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #ee3465;
  display: block;
}

.profile__link:hover {
  opacity: 0.8;
  transition: opacity 0.5s ease 0s;
}

@media screen and (max-width: 900px) {
  .profile {
    margin-top: 235px;
  }

  .profile__title {
    margin-bottom: 87px;
  }

  .profile__container:last-of-type {
    margin-bottom: 193px;
  }
}

@media screen and (max-width: 640px) {
  .profile {
    max-width: 260px;
    margin-top: 70px;
  }

  .profile__title {
    margin-bottom: 72px;
  }

  .profile__link {
    font-size: 12px;
    line-height: 15px;
  }

  .profile__button {
    font-size: 12px;
    line-height: 15px;
  }

  .profile__container:last-of-type {
    margin-bottom: 363px;
  }
}
